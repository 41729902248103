<template>
  <div class="flex items-center justify-center p-6 min-h-screen bg-teal-800">
    <div class="w-full max-w-md">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuestLayout',
}
</script>
