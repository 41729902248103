<template>
  <div>
    <Head :title="request.dealer.name" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/requests">Solicitudes</Link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.dealer.name }} : {{ request.state_name }}
    </h1>
    <div v-if="request.state != 'finish'" class="bg-white rounded-md shadow overflow-hidden px-6 py-6 mb-8 lg:w-1/2">
      <text-input v-model="stateForm.note" label="Nota" class="mb-6" />
      <select-input v-if="request.state == 'approve'" v-model="stateForm.group" class="pb-8 pr-6 w-full" label="Define el grupo de este Distribuidor">
        <option value="">Seleccionar</option>
        <option v-for="group in groups" :key="group.id" :value="group.id">
          {{ group.name }}
        </option>
      </select-input>
      <div class="flex items-center justify-between">
        <button v-if="request.state != 'finish'" class="btn-blue" @click="changeState(request.id)">
          Cambiar estado
        </button>
      </div>
    </div>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.dealer.name" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Nombres" />
          <text-input v-model="form.dealer.lastname" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Apellidos" />
          <text-input v-model="form.dealer.email" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Correo electrico" />
          <text-input v-model="form.dealer.phone" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Telefono" />
          <text-input v-model="form.dealer.state" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Estado" />
          <text-input v-model="form.dealer.town" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Municipio" />
          <text-input v-model="form.dealer.address" disabled class="pb-8 pr-6 w-full lg:w-1/3" label="Direccion" />
        </div>
      </form>
    </div>
    <h2 class="mt-12 text-2xl font-bold">Actividad</h2>
    <div class="mt-6 bg-white rounded shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Mensaje</th>
        </tr>
        <tr v-for="log in request.logs" :key="log.id">
          <td class="border-t flex items-center px-6 py-4 focus:text-indigo-500">{{ log.message }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'

export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput
  },
  layout: Layout,
  props: {
    request: Object,
    groups: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.request,
      group: '',
      stateForm: this.$inertia.form({
        note: null,
        group: ""
      })
    }
  },
  methods: {
    changeState(request_id)
    {
      const response = confirm("Estas seguro que quieres hacer este cambio")
      if(response) this.stateForm.post(`/requests/change-state/${request_id}`)
      this.stateForm.note = ''
    }
  },
}
</script>
