<template>
    <div>
        <div v-if="mode == 'select'">
            <button v-for="method in payment_methods" :key="method.id" @click="getMethod(method)" class="btn-blue m-2">
                {{ method.name }}
            </button>
        </div>
        <div v-if="mode == 'form'">
            <form @submit.prevent="addPay">
                <text-input v-if="payment_method.change_currency_id" v-model="amountAlternate" type="number" step="0.000001" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto Bs" />
                <text-input :disabled="payment_method.change_currency_id" v-model="form.amount" type="number" step="0.00001" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
                <text-input v-if="!payment_method.change" v-model="form.reference" :error="form.errors.reference" class="pb-8 pr-6 w-full" label="Referencia" />

                <div v-if="balance < form.amount" class="text-xl font-bold">
                    Cambio: {{ payment_method.currency.symbol }} {{ form.amount - balance }}
                </div>

                <div class="flex items-center justify-center">
                    <button type="button" @click="mode = 'select'" class="bg-black p-2 rounded text-white text-md w-100">Cancelar</button>
                    <button type="submit" class="text-md my-2 p-2 text-white bg-teal-800 rounded ml-2">Agregar pagos</button>
                </div>
            </form>           
        </div>
    </div>
</template>

<script>
import TextInput from './TextInput.vue'
export default {
  components: { TextInput },
    props: {
        payment_methods: Object,
        balance: Number,
        change: Number,
        order: Object,
        quick: Boolean
    },
    data() {
        return {
            mode: 'select',
            payment_method: null,
            form: this.$inertia.form({
                method: '',
                amount: 0,
                reference: '',
                quick: true,
                order_id: null,
                amount_bs: 0
            }),
            amountAlternate: 0
        }
    },
    watch: {
        'amountAlternate' : function(val) 
        {
            this.form.amount = val / this.change;
        },
        quick: function() 
        {
            alert('estoy aqui')
        }
    },
    methods: {
        cleanPay(){
            this.mode = 'select'
            this.payment_method = null
            this.form = this.$inertia.form({
                method: '',
                amount: 0,
                reference: '',
                quick: true,
                order_id: null
            })
            this.amountAlternate = 0
        },
        getMethod(method)
        {
            this.mode = 'form'
            this.payment_method = method
        },
        addPay() {
            if (!this.payment_method.change) {
                if (!this.form.reference) {
                    alert('Debe ingresar una referencia')
                    return false
                }
            }
            if (this.order.total < parseInt(this.form.amount) + parseInt(this.order.balance)) {
                let newAmount = this.form.amount - (parseFloat(this.form.amount) - parseFloat(this.order.total));
                if(parseInt(newAmount) + parseInt(this.order.balance) > this.order.total)
                {
                    return alert('No se puede agregar este pago si el monto es mayor al balance')
                }
                this.form.amount = newAmount
            }
            this.form.method = this.payment_method.name
            this.form.order_id = this.order.id
            this.form.amount_bs = this.amountAlternate

            
            this.form.post('/orders/pay')
            this.cleanPay();
        }
    }
}
</script>