<template>
  <div>
    <Head title="Crear productos" />
    <button class="bg-indigo-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/products">Productos</Link>
      <span class="text-indigo-400 font-medium">/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.code" :error="form.errors.code" class="pb-8 pr-6 w-full lg:w-1/2" label="Codigo" />
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
          <text-input v-model="form.description" :error="form.errors.description" class="pb-8 pr-6 w-full lg:w-1/2" label="Descripcion" />
          <text-input v-for="(group, key) in groups" :key="key" v-model="form.prices[key]" :error="form.errors.price" class="pb-8 pr-6 w-full lg:w-1/2" :label="`Precio ${group.name}`" />
          <text-input v-model="form.cost" :error="form.errors.cost" class="pb-8 pr-6 w-full lg:w-1/2" label="Costo" />
          <!-- <text-input v-model="form.line_name" :error="form.errors.line_name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre de linea" /> -->
          <!-- <text-input v-model="form.alias" :error="form.errors.alias" class="pb-8 pr-6 w-full lg:w-1/2" label="Alias" /> -->
          <select-input v-model="form.brand_id" :error="form.errors.brand_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Marca">
            <option value="">Seleccionar</option>
            <option v-for="(brand, key) in brands" :key="key" :value="brand.id">{{ brand.name }}</option>
          </select-input>
          <select-input v-model="form.product_category_id" :error="form.errors.product_category_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Categoria">
            <option value="">Seleccionar</option>
            <option v-for="(productCategory, key) in productCategories" :key="key" :value="productCategory.id">{{ productCategory.name }}</option>
          </select-input>
          <select-input v-model="form.contable" :error="form.errors.contable" class="pb-8 pr-6 w-full lg:w-1/2" label="Contable">
            <option value="">Seleccionar</option>
            <option value="0">No</option>
            <option value="1">Si</option>
          </select-input>
          <file-input v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Photo" />
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Crear producto</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FileInput from '@/Shared/FileInput'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    FileInput
  },
  layout: Layout,
  remember: 'form',
  props: {
    groups: Object,
    brands: Object,
    productCategories: Object
  },
  data() {
    return {
      form: this.$inertia.form({
        code: "",
        name: "",
        description: "",
        prices: this.groups.map(group => 0),
        brand_id: "",
        volumen: "",
        weight: "",
        quantity: 0,
        contable: 1,
        alias: "",
        line_name: "",
        cost: 0,
        product_category_id: '',
        photo: null
      }),
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    store() {
      this.form.post('/products')
    },
  },
}
</script>
