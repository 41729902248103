<template>
  <div>
    <Head title="Reports" />
    <h1 class="mb-8 text-3xl font-bold">Reporte</h1>

    <div class="bg-white p-5 flex">
      <text-input v-model="form.from" label="Desde" class="pb-8 pr-6 w-full lg:w-1/2" type="date" />
      <text-input v-model="form.to" label="Hasta" class="pb-8 pr-6 w-full lg:w-1/2" type="date" />
      <select-input v-model="form.modality" label="Modalidad" class="pb-8 pr-6 w-full lg:w-1/2">
        <option value="">Seleccionar</option>
<!--        <option value="pdf">PDF</option>-->
        <option value="advance">Detallado</option>
        <option value="simple">Simple</option>
      </select-input>
      <select-input v-model="form.nulled" label="Anulados" class="pb-8 pr-6 w-full lg:w-1/2">
        <option value="">Seleccionar</option>
<!--        <option value="pdf">PDF</option>-->
        <option value="1">Si</option>
        <option value="0">No</option>
      </select-input>
      <select-input v-model="form.type" label="Formato" class="pb-8 pr-6 w-full lg:w-1/2">
        <option value="">Seleccionar</option>
<!--        <option value="pdf">PDF</option>-->
        <option value="excel">Excel</option>
      </select-input>
      <div class="mt-6">
        <button class="btn-blue" @click="generateReport()">Generar reporte</button>
      </div>
    </div>

    <div class="bg-white p-5 mt-2">
      <h1 class="mb-8 text-xl font-bold">Productos por deposito</h1>
      <div v-for="deposit in deposits" :key="deposit.key" class="m-2 flex">
        <a target="_blank" :href="`/products/${deposit.id}/deposits`" class="btn-blue">{{deposit.name}}</a>
      </div>
    </div>

    <div v-if="count" class="pt-3">
      <a :href="`/orders-${form.orderNumber}.xlsx`">Descarga aqui</a>
    </div>


    <div class="flex" v-if="role == 'admin' || role == 'storer'">
      <div>
        <h3 class="py-2 text-xl font-bold">Productos por agotarse</h3>
        <div class="bg-white rounded-md shadow">
          <table class="w-full">
            <thead>
              <tr class="text-left font-bold">
                <th class="pb-4 pt-6 px-6">Código</th>
                <th class="pb-4 pt-6 px-6">Nombre</th>
                <th class="pb-4 pt-6 px-6">Cantidad</th>
              </tr>
            </thead>
              <tr v-for="prod in productsSoldout" :key="prod.id">
                <td class="px-6 py-4 focus:text-indigo-500">{{ prod.code }}</td>
                <td class="px-6 py-4 focus:text-indigo-500">{{ prod.name }}</td>
                <td class="px-6 py-4 focus:text-indigo-500">{{ prod.quantity }}</td>
              </tr>
            <tr v-if="!productsSoldout">
              <td class="px-6 py-4 border-t" colspan="4">No hay productos por agotarse.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from "@/Shared/SelectInput.vue";

export default {
  components: {
    SelectInput,
    TextInput,
    Head,
  },
  layout: Layout,
  props: {
    order: String,
    deposits: Object
  },
  data() {
    return {
      form: this.$inertia.form({
        from: '',
        to: '',
        type: 'excel',
        full: false,
        nulled: 0,
        modality: 'simple',
        orderNumber: Math.floor(Math.random() * (59999 - 10) + 10)
      }),
      count: 0
    }
  },
  methods: {
    getRandom() {
        this.form.orderNumber = Math.random() * (59999 - 10) + 10
    },
    generateReport(){
      return this.form.post('/orders/report', {onSuccess: (data) => {
          this.count = 1
          console.log(data)
        }})


    },
  },
}
</script>
