<template>
    <div>
      <Head title="Crear gasto" />
      <h1 class="mb-8 text-3xl font-bold">
        <Link class="text-indigo-400 hover:text-indigo-600" href="/spents">Gasto</Link>
        <span class="text-indigo-400 font-medium">/</span> Crear
      </h1>
      <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
        <form @submit.prevent="store">
          <div class="flex flex-wrap -mb-8 -mr-6 p-8">
            <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
            <text-input v-model="form.date" :error="form.errors.date" class="pb-8 pr-6 w-full lg:w-1/2" type="date" label="Fecha" />
            <text-input v-model="form.amount" :error="form.errors.amount" class="pb-8 pr-6 w-full lg:w-1/2" label="Monto" />
            <select-input v-model="form.spent_category_id" :error="form.errors.spent_category_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Categoria">
                <option value="">Seleccionar</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{category.name}}
                </option>
            </select-input>
            <select-input v-model="form.method" :error="form.errors.method" class="pb-8 pr-6 w-full lg:w-1/2" label="Método">
                <option value="">Seleccionar</option>
                <option value="efectivo">Efectivo</option>
                <option value="transferencia">Transferencia</option>
                <option value="pago-movil">Pago Movil</option>
                <option value="zelle">Zelle</option>
            </select-input>
          </div>
          <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
            <loading-button :loading="form.processing" class="btn-blue" type="submit">Crear gasto</loading-button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Head, Link } from '@inertiajs/inertia-vue3'
  import Layout from '@/Shared/Layout'
  import TextInput from '@/Shared/TextInput'
  import LoadingButton from '@/Shared/LoadingButton'
  import SelectInput from '@/Shared/SelectInput'
  
  export default {
    components: {
      Head,
      Link,
      LoadingButton,
      TextInput,
      SelectInput
    },
    props: {
        categories: Object
    },
    layout: Layout,
    remember: 'form',
    data() {
      return {
        form: this.$inertia.form({
          name: "",
          amount: 0,
          spent_category_id: '',
          date: '',
          method: ''
        }),
      }
    },
    methods: {
      store() {
        this.form.post('/spents')
      },
    },
  }
  </script>
  
  