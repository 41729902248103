<template>
  <div>
    <div class="flex items-center justify-between mb-6">
      <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">{{ namePlural }}</h1>
      <div v-if="exportButtons">
        <slot name="exportArea" />
      </div>
    </div>
    <div class="flex items-center justify-between mb-6">
      <slot name="search" />
      <div>
        <Link class="btn-blue" :href="createHref">
            <span>Crear</span>
            <span class="hidden md:inline">&nbsp;{{ name }}</span>
        </Link>
        <slot name="createMore" />
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import SearchFilter from '@/Shared/SearchFilter'
export default {
    props: {
        name: String,
        namePlural: String,
        createHref: String,
        exportButtons: Boolean,
        modelValue: String,
    },
    components: {
        Head,
        Link,
        SearchFilter
    },
    methods: {
        back() {
            window.history.back();
        }
    }
}
</script>
