<template>
  <div>
    <Head :title="`${form.name}`" />
    <button class="bg-teal-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/clients">Clientes</Link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }} (${{form.balance}})
    </h1>

    <div class="max-w-full bg-white rounded-md shadow-overflow-hidden mb-3">
      <div class="flex items-center rounded-t-md px-8 py-4 bg-teal-800 text-white">
        Lista de ordenes en proceso
      </div>
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">#</th>
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Observacion</th>
          <th class="pb-4 pt-6 px-6">Vendedor</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr
          v-for="order in orders"
          :key="order.id"
          :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${order.order_state === 'cancel' ? 'bg-red-200' : ''}`">
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.id }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.date }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span v-if="order.order_state == 'cancel'">ANULADA</span>
              <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
              <span v-if="order.order_state == 'finish'">FINALIZADA</span>
              <span v-if="order.order_state == 'pay'">PAGADA</span>
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.comentary }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.responsible ? order.responsible.first_name+" "+order.responsible.last_name : 'Transaccion directa' }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              ${{ order.total }}
            </Link>
          </td>
        </tr>
      </table>
    </div>

    <div class="max-w-full bg-white rounded-xl shadow-overflow-hidden mb-3">
      <div class="flex items-center rounded-t-md px-8 py-4 bg-teal-800 text-white">
        Registrar pagos
      </div>
      <div>
      <div class="p-5">
        <div class="pb-8 pr-6 w-full">
          <label for="">Metodo de pago</label>
          <select v-model="pay.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option v-for="method in payment_methods" :key="method.id" :value="method.name">{{method.name}}</option>
          </select>
        </div>
        <text-input v-model="pay.amount" type="number" step="0.10" :error="pay.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
        <text-input v-if="pay.method === 'transferencia' || pay.method === 'transferencia-usd' || pay.method === 'zelle' || pay.method === 'pago-movil'" v-model="pay.reference" :error="pay.errors.reference" class="pb-8 pr-6 w-full" label="Referencia" />
        <input class="form-input" v-model="pay.date" type="date">

        <button @click="storePay" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Agregar pago</button>
      </div>
    </div>
    </div>


    <div class="max-w-full bg-white rounded-md shadow overflow-hidden">
      <div class="flex items-center px-8 py-4 bg-teal-800 text-white border-t border-gray-100">
        Actualizar cliente
      </div>
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre y Apellido" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="Correo electrónico" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="Teléfono" />
          <text-input v-model="form.identification" :error="form.errors.identification" class="pb-8 pr-6 w-full lg:w-1/2" label="Alias o Cédula" />
          <text-input v-model="form.companyName" :error="form.errors.companyName" class="pb-8 pr-6 w-full lg:w-1/2" label="Empresa" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Dirección" />
          <div class="pb-8 pr-6 w-full lg:w-1/2">
            <label for="">Tipo de cliente</label>
            <select v-model="form.typeClient" class="w-full mt-2 form-input">
              <option value="">Seleccionar</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>

        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Actualizar clientes</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    client: Object,
    organizations: Array,
    orders: Array,
    payment_methods: Object
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.client.name,
        email: this.client.email,
        phone: this.client.phone,
        identification: this.client.identification,
        companyName: this.client.companyName,
        address: this.client.address,
        balance: this.client.balance,
        typeClient: this.client.typeClient
      }),
      pay: this.$inertia.form({
        client_id: this.client.id,
        method: '',
        amount: 0,
        reference: '',
        date: ''
      })
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    update() {
      this.form.put(`/clients/${this.client.id}`)
    },
    destroy() {
      if (confirm('Are you sure you want to delete this client?')) {
        this.$inertia.delete(`/clients/${this.client.id}`)
      }
    },
    storePay() {
        this.pay.post('/payments')
    },
    restore() {
      if (confirm('Are you sure you want to restore this client?')) {
        this.$inertia.put(`/clients/${this.client.id}/restore`)
      }
    },
  },
}
</script>
