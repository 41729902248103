<template>
    <div>
        <Head title="Transferencia de Productos" />
        <h1 class="mb-8 text-3xl font-bold">Transferencia de Productos</h1>
        <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Alias</th>
          <th class="pb-4 pt-6 px-6">Región</th>
          <th class="pb-4 pt-6 px-6">Dirección</th>
        </tr>
        <tr v-for="product in products['data']" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits//edit`">
              
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits//edit`">
              
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits//edit`">
              
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/deposits//edit`">
              
            </Link>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay depositos.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="products.links" />
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Icon,
    Link,
  },
  layout: Layout,
  props: {
    deposits: Object,
  },
}
</script>