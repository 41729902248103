<template>
  <div>
    <Head title="Registros" />
    <div class="flex items-center justify-between mb-6">
      <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">Registros</h1>
    </div>
    <div class="flex items-center justify-between mb-6">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset"> </search-filter>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Usuario</th>
          <th class="pb-4 pt-6 px-6">Descripcion</th>
          <th class="pb-4 pt-6 px-6">Categoria</th>
        </tr>
        <tr v-for="log in logs.data" :key="log.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100`">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ log.created_at }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" tabindex="-1">
              {{ log.user.first_name }} {{ log.user.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" tabindex="-1">
              {{ log.description }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" tabindex="-1">
              {{ log.category.name }}
            </Link>
          </td>
        </tr>
        <tr v-if="logs.data.length === 0">
          <td class="px-6 py-4 border-t" colspan="4">No hay Registros.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="logs.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  components: {
    Head,
    Link,
    Pagination,
    SearchFilter,
  },
  layout: Layout,
  props: {
    filters: Object,
    logs: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/logs', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    back() {
      window.history.back()
    },
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
