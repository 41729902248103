<template>
  <div>
    <Head title="Productos" />
    <h1 class="mb-8 text-3xl font-bold">Devoluciones</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-3">Cantidad</th>
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Cantidad</th>
          <th class="pb-4 pt-6 px-6">Marca</th>
        </tr>
        <tr v-for="product in products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="boder-t px-4 py-4">
            <text-input v-model="quantities.qtys[product.id]" type="number" placeholder="Cantidad" />
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.code }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.quantity }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.brand.name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
      <loading-button class="btn-blue mx-4 my-4" type="button" @click="generate">Crear salida de productos</loading-button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'

export default {
  components: {
    Head,
    Icon,
    Link,
    TextInput,
  },
  layout: Layout,
  props: {
    products: Object,
  },
  data(){
    return {
      quantities: this.$inertia.form({
        qtys: [],
      }),
    }
  },
  methods: {
    generate(){
      this.quantities.post('/returns')
    },
  },

}
</script>
