<template>
  <div>
    <Head title="Solicitudes" />
    <h1 class="mb-8 text-3xl font-bold">Solicitudes</h1>
    <div class="flex items-center justify-between mb-6">
      <!-- <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-700">Eliminados:</label>
        <select v-model="form.trashed" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">Eliminados</option>
          <option value="only">Solo eliminados</option>
        </select>
      </search-filter> -->
      <!-- <Link class="btn-blue" href="/organizations/create">
        <span>Create</span>
        <span class="hidden md:inline">&nbsp;S</span>
      </Link> -->
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="pb-4 pt-6 px-6">ID</th>
            <th class="pb-4 pt-6 px-6">Nombre</th>
            <th class="pb-4 pt-6 px-6">Apellido</th>
            <th class="pb-4 pt-6 px-6">Compañia</th>
            <th class="pb-4 pt-6 px-6">Correo electronico</th>
            <th class="pb-4 pt-6 px-6">Telefono</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
            <th class="pb-4 pt-6 px-6" colspan="2">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="request in requests.data" :key="request.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                #{{ request.id }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.dealer.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.dealer.lastname }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.dealer.company }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.dealer.email }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.dealer.phone }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ request.created_at }}
              </Link>
            </td>
            <td class="border-t">
              <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/requests/${request.id}/edit`">
                {{ getState(request.state) }}
              </Link>
            </td>
            <td class="w-px border-t">
              <Link class="flex items-center px-4" :href="`/requests/${request.id}/edit`" tabindex="-1">
                <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
              </Link>
            </td>
          </tr>
          <tr v-if="requests.data.length === 0">
            <td class="px-6 py-4 border-t" colspan="7">No se encontraron solicitudes.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination class="mt-6" :links="requests.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
  },
  layout: Layout,
  props: {
    filters: Object,
    requests: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/requests', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
    getState(state) {
      switch (state) {
      case 'init':
        return 'Inicio'
      case 'contact':
        return 'Distribuidor contactado'
      case 'request':
        return 'En espera de solicitud de recaudos'
      case 'revision':
        return 'En revision'
      case 'approve':
        return 'Aprobado'
      case 'finish':
        return 'Finalizado'
      default:
        break
      }
    },
  },
}
</script>
