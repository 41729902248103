<template>
  <div>
    <div v-if="mode == 'find'">
      <text-input v-model="search" label="Nombre, Alias o Cédula" placeholder="Busca cliente por cédula, alias o nombre" class="pb-3 pr-6 w-full" />
      <div class="p-2">
        <button 
            v-for="client in clients" 
            :key="client.id" 
            class="p-1 bg-gray-200 rounded w-full text-left"
            @click="addClient(client)"
        >
            {{ client.name }} - {{ client.identification }}
        </button>
      </div>
      <div class="m-2 text-center">
        <button @click="mode = 'create'" class="text-blue-600 font-bold">O, crea un nuevo cliente</button>
      </div>
    </div>
    <div v-if="mode == 'show'" class="border-2 border-blue-600 rounded p-2">
        <h1>{{client.name}}</h1>
        <p>Cédula, RIF o Alias: {{client.identification}}</p>
        <p>Correo eléctronico: {{client.email}}</p>
        <div>
            <button @click="mode = 'find'" type="button" class="font-bold text-blue-600">Editar</button>
        </div>
    </div>
    <div v-if="mode == 'create'">
        <form @submit.prevent="saveClient" class="flex flex-wrap mb-8 mr-6 p-8">
          <!-- <text-input v-model="form.client.companyName" label="Empresa" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <text-input v-model="form.client.name" label="Nombre y Apellido de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <!-- <text-input v-model="form.client.email" label="Email de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" /> -->
          <text-input v-model="form.client.identification" label="Alias o Cédula de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.phone" label="Telefono de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.address" label="Direccion de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.balance" disabled="true" label="Monto adeudado" class="pb-8 pr-6 w-full lg:w-1/2" />
          <div class="pb-8 pr-6 w-full lg:w-1/2">
            <label for="">Tipo de cliente</label>
            <select v-model="form.client.typeClient" class="form-input mt-2 w-full">
              <option value="">Seleccionar</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>
          <div>
            <button type="submit" class="btn-blue">Guardar</button>
          </div>
        </form>
    </div>
  </div>
</template>

<script>
import TextInput from './TextInput.vue'
import axios from 'axios';

export default {
  components: { TextInput },
  data: () =>{
    return {
      mode: 'find',
      form: {
        client: {},
        search: ''
      },
      clients: [],
      client: null,
      search: ''
    }
  },
  methods: {
    addClient(client)
    {
        this.client = client;
        this.$emit('addClient', client);
        this.mode = 'show';
    },
    getClients()
    {
        if(this.search)
        {
            axios.get(`/getClients?search=${this.search}`).then(response => this.clients = response.data.data);    
        }else {
            axios.get('/getClients').then(response => this.clients = response.data.data);
        }
        
    },
    saveClient()
    {
        axios.post('/saveClient', this.form.client)
            .then(response => {
                this.client = response.data.data;
                this.mode = 'show';
                this.$emit('addClient', response.data.data);
            })
            .catch(err => console.log(err))
    }
  },
  watch: {
    'search': function (val) {
        this.getClients();
    }
  },
  mounted() {
    this.getClients();
  }
}
</script>
