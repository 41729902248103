<template>
  <div>
    <Head title="Inventarios" />
    
    <app-toolbar
      name="Inventario"
      namePlural="Inventarios"
      createHref="/inventories/create"
      :exportButtons="false"
    >
    <template v-slot:search>
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="form-select mt-1 w-full">
          <option :value="null" />
          <option value="with">Con eliminadas</option>
          <option value="only">Solo eliminadas</option>
        </select>
      </search-filter>
      </template>
    </app-toolbar>

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Usuario</th>
          <th class="pb-4 pt-6 px-6">Cantidad agregada</th>
          <th class="pb-4 pt-6 px-6">Estatus</th>
        </tr>
        <tr v-for="inventory in inventories" :key="inventory.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDate(inventory.created_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ inventory.user.first_name }} {{ inventory.user.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ inventory.quantity }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ inventory.status == 'request' ? 'Solicitud' : 'Procesado' }}
            </Link>
          </td>
        </tr>
        <tr v-if="!inventories">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import AppToolbar from '../../Shared/AppToolbar.vue'
import SearchFilter from '../../Shared/SearchFilter.vue'


export default {
  components: {
    Head,
    Icon,
    Link,
    AppToolbar,
    SearchFilter,
  },
  layout: Layout,
  props: {
    inventories: Object,
    user: Object,
  },
  data() {
    return {
      form: {

      },
    }
  },
  methods: {
    getDate(date)
    {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    }
  }
}
</script>
