<template>
  <div class="container">
    <Head :title="`Venta #${order.number_order ?? order.id} ${order.order_state == 'cancel' ? 'PEDIDO ANULADO' : ''}`" />
    <div class="flex-col items-center p-5 text-center bg-white rounded-lg shadow-lg">
      <div>
        <i class="fa fa-check text-green-500 text-2xl"></i>
        <h1 class="mb-8 text-2xl font-bold">Venta #{{ order.number_order }} creado correctamente</h1>
        <div v-if="order.order_state == 'request'" class="pb-2">
          <strong>Estado: </strong>
          <span v-if="order.order_state == 'cancel'">ANULADA</span>
          <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
          <span v-if="order.order_state == 'finish'">FINALIZADA</span>
          <span v-if="order.order_state == 'pay'">PAGADA</span>
          <span v-if="order.order_state == 'delivery'">EN ESPERA DE DESPACHO</span>
          <div>
            <button class="font-bold text-red-500 p-2" type="button" @click="cancelOrder()"> Anular orden</button>
          </div>
        </div>
        <div v-else>
          <button class="font-bold text-blue-300 p-2" type="button" @click="print('usd')"><i class="fa fa-print"></i> Imprimir</button> <br>
          <button class="font-bold text-blue-300 p-2" type="button" @click="print('ves')"><i class="fa fa-print"></i> Imprimir en BsD</button><br>
          <button v-if="cop_change" class="font-bold text-blue-300 p-2" type="button" @click="print('cop')"><i class="fa fa-print"></i> Imprimir en Cop</button><br>
          <a class="font-bold text-green-500 p-2" type="button" href="/pos">Nueva venta</a>
          <!-- <button class="font-bold text-blue-300 p-2" type="button" @click="print('cop')"><i class="fa fa-print"></i> Imprimir en Pesos</button> -->
        </div>

      </div>
      <div class="flex flex-col md:flex-row">
        <div class="w-full md:w-70"><div>
          <div>
            <table>
              <thead>
                <tr>
                  <th class="pb-4 pt-6 px-6">Producto</th>
                  <th class="pb-4 pt-6 px-6">Descripcion</th>
                  <th>Cantidad</th>
                  <th class="pb-4 pt-6 px-6">Precio</th>
                  <th class="pb-4 pt-6 px-6">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, key) in order.products" :key="key" :class="`${product.dispatch ? 'bg-blue-300' : ''}`">
                  <td class="p-2 text-center border-t">
                    {{ product.product.name }}
                  </td>
                  <td class="p-2 text-center border-t">
                    {{ product.product.description }}
                  </td>
                  <td class="p-2 text-center border-t">
                    {{ product.quantity }}
                  </td>
                  <td class="p-2 text-center border-t">${{ product.price }}</td>
                  <td class="p-2 text-center border-t">${{ product.price * product.quantity }}</td>
                </tr>
              </tbody>
              <tfoot class="px-6 py-2" style="background-color: #f3f3f3">
                <tr>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"><strong>Total</strong></td>
                  <td class="p-2 text-center border-t">${{ order.total }} (BsD. {{(order.total - order.balance) * change}})</td>
                </tr>
                <tr>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"><strong>Deuda</strong></td>
                  <td class="p-2 text-center border-t">${{ order.total - order.balance }} (BsD .{{(order.total - order.balance) * change}})</td>
                </tr>
                <tr>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"></td>
                  <td class="p-2 text-center border-t"><strong>Balance</strong></td>
                  <td class="p-2 text-center border-t">${{ order.balance }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div></div>
        <div class="w-full md:w-30">
            <div>
          <div v-if="role == 'financial' || role == 'admin' || role == 'sales'" class="m-2">
            <h2 class="mb-8 text-xl font-bold">Pagos</h2>

            <payment-box :quick="true" :payment_methods="payment_methods" :balance="order.total - order.balance" :change="change" :order="order" />

            <!-- <div class="pt-2">
              <div class="pb-8 pr-6 w-full">
                <label for="">Metodo de pago</label>
                <select v-model="form.method" class="form-input mt-2 w-full">
                  <option value="">Seleccionar</option>
                  <option value="efectivo">Efectivo divisas</option>
                  <option value="transferencia">Transferencia Bs</option>
                  <option value="pago-movil">Pago móvil Bs</option>
                  <option value="transferencia-usd">Transferencia Divisas</option>
                  <option value="zelle">Zelle</option>
                  <option value="credito">Credito</option>
                </select>
              </div>
              <text-input v-model="form.amount" type="number" step="0.10" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
              <text-input v-if="form.method === 'transferencia' || form.method === 'transferencia-usd' || form.method === 'zelle' || form.method === 'pago-movil'" v-model="form.reference" :error="form.errors.reference" class="pb-8 pr-6 w-full" label="Referencia" />

              <button @click="addPay" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Agregar pagos</button>
            </div> -->
            <div>
              <table class="w-full whitespace-nowrap">
                <thead>
                  <tr>
                    <th class="pb-4 pt-6 px-6">Metodo</th>
                    <th class="pb-4 pt-6 px-6">Monto</th>
                    <th class="pb-4 pt-6 px-6">Fecha</th>
                    <th class="pb-4 pt-6 px-6">Referencia</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="pay in order.pays" :key="pay.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
                    <td class="p-2 text-center border-t capitalize">
                      {{ pay.method }}
                    </td>
                    <td class="p-2 text-center border-t">
                      {{ pay.amount }} <span v-if="pay.amount_bs > 0">(BsD. {{pay.amount_bs}})</span>
                    </td>
                    <td class="p-2 text-center border-t">
                      {{ pay.created_at }}
                    </td>
                    <td class="p-2 text-center border-t">
                      {{ pay.reference }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="flex">
        
        
      </div>
    </div>
  </div>
</template>
<script>
import LayoutFullVue from '../../Shared/LayoutFull.vue'
import TextInput from '../../Shared/TextInput.vue'
import { Head } from '@inertiajs/inertia-vue3'
import PaymentBox from '../../Shared/PaymentBox.vue'

export default {
  layout: LayoutFullVue,
  components: {
    TextInput,
    Head,
    PaymentBox
  },
  props: {
    order: Object,
    role: Object,
    payment_methods: Object,
    change: Number
  },
  data() {
    return {
      form: this.$inertia.form({
        method: 'efectivo',
        amount: this.order.total - this.order.balance,
        order_id: this.order.id,
        quick: true,
      }),
    }
  },
  methods: {
    cancelOrder() {
      if (confirm('Estas seguro que quieres anular esta orden?')) {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },
    print(exchange) {
      window.open(`/orders/${this.order.id}/report-thermical/${exchange}`, `Venta #${this.order.number_order}`, "width=400,height=500", 'toolbar=no');
    },
    addPay() {
      if (this.form.method === 'transferencia' || this.form.method === 'transferencia-usd' || this.form.method === 'zelle') {
        if (!this.form.reference) {
          alert('Debe ingresar una referencia')
          return false
        }
      }
      if (this.order.total < parseInt(this.form.amount) + parseInt(this.order.balance)) {
        return alert('No se puede agregar este pago si el monto es mayor al balance')
      }
      this.form.order_id = this.order.id
      this.form.post('/orders/pay')
      console.log(this.form)

      this.cleanPay()
    },
    cleanPay() {
      this.form = this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
      })
    },
  },
}
</script>
