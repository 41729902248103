<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Escritorio</h1>

    <div v-if="role == 'admin'" class="grid grid-cols-1 gap-4 md:grid-cols-4">
      <box-dashboard v-if="role == 'financial' || role == 'admin'" :title="'Cuentas por cobrar'">
        <p class="text-center text-3xl font-black">${{ balanceTotal.toLocaleString('es') }}</p>
      </box-dashboard>
      <box-dashboard :title="'Ventas del mes'">
        <p class="text-center text-3xl font-black">
          ${{ orderMonthTotal.toFixed(2) }}
        </p>
      </box-dashboard>
      <box-dashboard :title="'Gastos del mes'">
        <p class="text-center text-3xl font-black">
          ${{ spentTotal.toFixed(2) }}
        </p>
      </box-dashboard>
      <box-dashboard :title="'Utilidad'">
        <p class="text-center text-3xl font-black">
          ${{ orderMonthTotal - spentTotal }}
        </p>
      </box-dashboard>
    </div>


    <h3 v-if="role == 'admin'" class="my-2">Estadisticas de productos</h3>
    <div v-if="role == 'admin'" class="grid grid-cols-1 gap-4 md:grid-cols-4">
      <box-dashboard v-if="role == 'financial' || role == 'admin'" :title="'Total de referencias'">
        <p class="text-center text-3xl font-black">{{ products }}</p>
      </box-dashboard>      
      <box-dashboard v-if="role == 'financial' || role == 'admin'" :title="'Costo total de inventario'">
        <p class="text-center text-3xl font-black">${{ productsStats.costTotal.toFixed(2) }}</p>
      </box-dashboard>
      <box-dashboard :title="'Costo total de venta'">
        <p class="text-center text-3xl font-black">
          ${{ productsStats.priceTotal.toFixed(2) }}
        </p>
      </box-dashboard>
      <box-dashboard :title="'Utilidad en productos'">
        <p class="text-center text-3xl font-black">
          ${{ productsStats.utilityTotal.toFixed(2) }}
        </p>
      </box-dashboard>
    </div>

    <div class="my-10" v-if="role == 'admin'">
      <h3 class="py-2 text-xl font-bold">Accesos directos</h3>
      <div class="flex flex-wrap gap-2">
      <a href="/organizations" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Tiendas</a>
      <a href="/deposits" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Depositos</a>
      <a href="/products" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Productos</a>
      <a href="/brands" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Marcas</a>
      <a href="/inventories" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Inventario</a>
      <a href="/orders" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Ventas</a>
      <a href="/spents" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Gastos</a>
      <a href="/taxes" class="bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">Tasas de cambio</a>
    </div>
    </div>

    <h3 v-if="role == 'admin' || role == 'sales'" class="py-2 text-xl font-bold">últimas ordenes</h3>
    <div v-if="role == 'admin' || role == 'sales'" class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Numero</th>
          <th class="pb-4 pt-6 px-6">Cliente</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Vendedor</th>
          <th class="pb-4 pt-6 px-6">Abonado</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders.data" :key="order.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDate(order.created_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client?.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span v-if="order.order_state == 'cancel'">ANULADA</span>
              <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
              <span v-if="order.order_state == 'finish'">FINALIZADA</span>
              <span v-if="order.order_state == 'pay'">PAGADA</span>
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> {{ order.responsible?.first_name }} {{ order.responsible?.last_name }} </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> ${{ order.balance }} </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500"> ${{ order.total }} </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay ordenes.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import BoxDashboard from '../../Shared/BoxDashboard.vue'

export default {
  components: {
    Link,
    Head,
    BoxDashboard,
  },
  layout: Layout,
  data() {
    return {
      role: this.$parent.auth.user.role,
    }
  },
  props: {
    total: Number,
    orders: Object,
    clientTotal: Number,
    balanceTotal: Number,
    products: Number,
    orderTotal: Number,
    spentTotal: Number,
    productsSoldout: Object,
    orderMonthTotal: Number,
    productsStats: Object
  },
  methods: {
    getDate(date) {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    },
  },
}
</script>
