<template>
  <div>
    <Head title="Clientes" />

    <app-toolbar name="Cliente" namePlural="Clientes" createHref="/clients/create" :exportButtons="true">
      <template v-slot:search>
        <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
          <label class="block text-gray-700">Trashed:</label>
          <select v-model="form.trashed" class="form-select mt-1 w-full">
            <option :value="null" />
            <option value="with">Con eliminadas</option>
            <option value="only">Solo eliminadas</option>
          </select>
        </search-filter>
      </template>
      <template v-slot:exportArea>
        <div>
          <a target="_blank" :href="`/clientsReports/excel`" class="mr-2 p-2 bg-green-500 rounded">
            <i class="fa fa-file text-white" />
          </a>
          <a target="_blank" :href="`/clientsReports/pdf`" class="p-2 bg-red-800 rounded">
            <i class="fa fa-print text-white" />
          </a>
        </div>
      </template>
    </app-toolbar>

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Alias o Cédula</th>
          <th class="pb-4 pt-6 px-6">Ventas</th>
          <th class="pb-4 pt-6 px-6">Correo electronico</th>
          <th class="pb-4 pt-6 px-6">Tipo de cliente</th>
          <th class="pb-4 pt-6 px-6">Empresa</th>
          <th class="pb-4 pt-6 px-6">Dirección</th>
          <th class="pb-4 pt-6 px-6">Adeudado</th>
          <th class="pb-4 pt-6 px-6" colspan="2">Telefono</th>
        </tr>
        <tr v-for="client in clients.data" :key="client.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${client.balance > 0 ? 'bg-red-300' : null}`">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/clients/${client.id}/edit`">
              {{ client.name }}
              <icon v-if="client.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.identification }}
            </Link>
          </td>
          <td class="border-t">
            <Link v-for="order in client.orders" :key="order.id" class="flex flex-row items-center px-6 py-4" :href="`/orders/${order.id}`" tabindex="-1">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.email }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.typeClient }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.companyName }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.address }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1"> {{ client.balance }}$ </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.phone }}
            </Link>
          </td>
          <td class="w-px border-t">
            <Link class="flex items-center px-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="clients.data.length === 0">
          <td class="px-6 py-4 border-t" colspan="4">No hay clientes.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="clients.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    AppToolbar,
    
  },
  layout: Layout,
  props: {
    filters: Object,
    clients: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/clients', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    back() {
      window.history.back()
    },
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
