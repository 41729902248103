<template>
  <div>
    <Head title="Transferencia de Productos" />
    <h1 class="mb-8 text-3xl font-bold">Transferencia de Productos</h1>
    <div class="flex items-center justify-between mb-6">
      <Link class="btn-blue" :href="`/deposits/${depositId}/transfer/create`">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Transferencia</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Deposito Emisor</th>
          <th class="pb-4 pt-6 px-6">Deposito receptor</th>
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
        </tr>
        <tr v-for="transfer in depositTransfers['data']" :key="transfer.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" > {{ transfer['emitter']['name'] }} </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" > {{ transfer['receiver']['name'] }} </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" > {{ transfer['date'] }} </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" > {{ transfer['state'] }} </Link>
          </td>
        </tr>
        <tr v-if="!depositTransfers">
          <td class="px-6 py-4 border-t" colspan="4">No hay transferencias.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="depositTransfers.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Icon,
    Link,
  },
  layout: Layout,
  props: {
    depositTransfers: Object,
    depositId: Number
  },
}
</script>
