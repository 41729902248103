<template>
  <img width="150px" height="50px" :src="src ?? '/logo.png'" />
</template>

<script>
export default {
  props: {
    src: String
  }
}
</script>
