<template>
  <div>
    <Head :title="`Editar marca`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/">Cuenta</Link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }} <img :src="logo" alt="">
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
          <text-input v-model="form.identification" :error="form.errors.identification" class="pb-8 pr-6 w-full lg:w-1/2" label="Identificación" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="Email" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="Teléfono" />
          <text-input v-model="form.reasonName" :error="form.errors.reasonName" class="pb-8 pr-6 w-full lg:w-1/2" label="Razon Social" />
          <text-input v-model="form.sidebarColor" :error="form.errors.sidebarColor" class="pb-8 pr-6 w-full lg:w-1/2" label="Sidebar Color" />
          <text-input v-model="form.textSidebarColor" :error="form.errors.textSidebarColor" class="pb-8 pr-6 w-full lg:w-1/2" label="Text Sidebar Color" />
          <text-input v-model="form.textHoverSidebarColor" :error="form.errors.textSidebarColor" class="pb-8 pr-6 w-full lg:w-1/2" label="Text Hover Sidebar Color" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Dirección" />
          <file-input v-model="form.logo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Logo" />
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-blue ml-auto" type="submit">Actualizar cuenta</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import FileInput from '@/Shared/FileInput'
export default {
  layout: Layout,
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    FileInput
  },
  props: {
    account: Object,
  },
  data() {
    return {
      logo: this.account.logo,
      form: this.$inertia.form({
        _method: 'put',
        name: this.account.name,
        address: this.account.address,
        reasonName: this.account.reasonName,
        sidebarColor: this.account.sidebarColor,
        textSidebarColor: this.account.textSidebarColor,
        textHoverSidebarColor: this.account.textHoverSidebarColor,
        logo: null,
        identification: this.account.identification,
        phone: this.account.phone,
        email: this.account.email
      }),
    }
  },
  methods: {
    update() {
      this.form.post(`/accounts/${this.account.id}`)
    },
  }
}
</script>
