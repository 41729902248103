<template>
  <div>
    <Head title="Productos" />
    <h1 class="mb-8 text-3xl font-bold">Inventarios</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <div class="p-5">
        <h3>Buscar productos</h3>
      </div>
      
      <div class="px-10">
        <div class="flex">
        <text-input label="Por Términos" class="pb-8 pr-6 w-full lg:w-1/2" v-model="form.search.terms" />
      </div>
      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <tr>
            <th>Codigo</th>
            <th>Producto</th>
            <th>disponibles</th>
          </tr>
          <tr v-for="(product, key) in products.data" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100" @click="addProduct(product)">
            <td class="p-2 text-center">{{ product?.code }}</td>
            <td class="p-2 text-center">{{ product?.name }}</td>
            <td class="p-2 text-center">{{ product?.quantity }}</td>
          </tr>
        </table>
      </div>
      </div>


      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-3">Cantidad</th>
          <th class="pb-4 pt-6 px-3">Deposito</th>
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Cantidad</th>
          <th class="pb-4 pt-6 px-6"></th>
        </tr>
        <tr v-for="product in items" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="boder-t px-4 py-4">
            <text-input v-model="quantities.qtys[product.id]" type="number" placeholder="Cantidad" />
          </td>
          <td class="boder-t px-4 py-4">
            <select-input v-model="quantities.deposits[product.id]">
              <option>Seleccionar</option>
              <option v-for="deposit in deposits" :key="deposit.id">{{ deposit.name }}</option>
            </select-input>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.code }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`products/${product.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.quantity }}
            </Link>
          </td>
          <td class="border-t">
            <button @click="removeProduct(product)" class="btn btn-danger">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
        <tr v-if="!products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
      <loading-button class="btn-blue mx-4 my-4" type="button" @click="generate">Crear entrada de productos</loading-button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'
import SelectInput from '@/Shared/SelectInput.vue'

export default {
  components: {
    Head,
    Link,
    TextInput,
    SelectInput,
  },
  layout: Layout,
  props: {
    product: Object,
    deposits: Array,
    products: Object,
  },
  data() {
    return {
      getQuantity(inventory)
      {
        let quantity = 0
        for (let inventory of inventory) {
          quantity += inventory.quantity
        }

        return quantity
      },
      quantities: this.$inertia.form({
        qtys: [],
        products: [],
        deposits: [],
      }),
      form: this.$inertia.form({
        code: '',
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
      }),
      prd: {
        name: '',
      },
      items: []
    }
  },
  watch: {
    'form.search.terms': function () {
      this.onSearchProductsChange()
    },
    'form.search.deposit': function () {
      this.onSearchProductsChange()
    },
    'form.search.brand_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.part_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.truck_model_id': function () {
      this.onSearchProductsChange()
    },
    'form.search.region_id': function () {
      this.onSearchProductsChange()
    },
  },
  methods: {
    removeProduct(product) {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.items
        let newProducts = products.filter((prd) => prd.id != product.id)
        this.items = newProducts
      }
    },
    onSearchProductsChange() {
      this.$inertia.get('/inventories/create', pickBy({search: this.form.search}), {
        preserveState: true,
        preserveScroll: true,
        replace: true
      })
    },
    generate() {
      this.quantities.post('/inventories')
    },
    addProduct(product) {
        this.items.push(product);
    },
  },
}
</script>
