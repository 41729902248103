<template>
  <div>
    <Head :title="`Venta #${order.number_order ?? order.id} | ${order.order_state == 'cancel' ? 'PEDIDO ANULADO' : ''}`" />

    <div class="flex justify-between">
      <div>
        <button class="mr-2 p-2 text-white bg-teal-800 rounded" @click="back()">
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="mb-8 text-3xl font-bold">Venta #{{ order.number_order ?? order.id }} <span class="text-red-500" v-if="order.order_state == 'cancel'">PEDIDO ANULADO</span></h1>
      </div>

      <div>
        <a target="_blank" :href="`/orders/${order.id}/report`" class="btn-blue mr-2 p-2 rounded"> <i class="fa fa-print text-white" /> Proforma </a>
        <a v-if="order.order_state != 'estimate'" target="_blank" :href="`/orders/${order.id}/report-storer`" class="btn-blue mr-2 p-2 rounded"> <i class="fa fa-print text-white" /> Nota de entrega </a>
        <a v-if="order.order_state != 'estimate'" target="_blank" :href="`/orders/${order.id}/report-invoice`" class="btn-blue p-2 mr-2 rounded"> <i class="fa fa-print text-white" /> Factura </a>
        <a v-if="order.order_state != 'estimate'" target="_blank" :href="`/pos/${order.id}/show`" class="btn-blue p-2 rounded"> <i class="fa fa-print text-white" /> POS </a>
      </div>
    </div>

    <div class="flex justify-between mb-5 p-5 bg-white rounded-md shadow overflow-x-auto">
      <div>
        <div class="pb-2">Cliente: {{ order.client.name }}</div>
        <div class="pb-2">Alias o Cédula: {{ order.client.identification }}</div>
        <div class="pb-2">Email: {{ order.client.email }}</div>
        <div class="pb-2">Telefono: {{ order.client.phone }}</div>
        <div class="pb-2"><strong>Balance:</strong> ${{ (order.balance).toFixed(2) }}</div>
        <div class="pb-2"><strong>Balance Bs:</strong> {{ (order.balance  * order.change).toFixed(2) }}</div>
        <div class="pb-2"><strong>Deuda:</strong> ${{( order.total - order.balance).toFixed(2) }}</div>
        <div class="pb-2"><strong>Deuda Bs:</strong> {{ ((order.total - order.balance) * order.change).toFixed(2) }}</div>
        <div class="pb-2"><strong>Total:</strong> ${{ order.total.toFixed(2) }}</div>
        <div class="pb-2"><strong>Total Bs:</strong> {{ (order.total * order.change).toFixed(2) }}</div>
        <div class="pb-2"><strong>Tienda:</strong> {{ order?.organization?.name }}</div>
        <div class="pb-2"><strong>Orden de compra:</strong> {{ order?.order_buy }}</div>
        <div class="pb-2"><strong>Observaciones:</strong> {{ order?.comentary }}</div>
        <div class="pb-2">
          <strong>Modalidad: </strong>
          {{ order.isCredit ? 'Credito' : 'Contado' }}
        </div>
        <div class="pb-2">
          <strong>Estado: </strong>
          <span v-if="order.order_state == 'estimate'">PRESUPUESTO</span>
          <span v-if="order.order_state == 'cancel'">ANULADA</span>
          <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
          <span v-if="order.order_state == 'finish'">FINALIZADA</span>
          <span v-if="order.order_state == 'pay'">PAGADA</span>
          <span v-if="order.order_state == 'delivery'">EN ESPERA DE DESPACHO</span>
        </div>
        <div class="pb-2">
          <button v-if="order.order_state == 'estimate'" @click="changeOrderState()" class="btn-blue">Procesar orden</button>
        </div>
      </div>
      <div class="flex">
        <div>Vendedor {{ order?.responsible?.first_name }} {{ order?.responsible?.last_name }}</div>
      </div>
    </div>

    <div v-if="(role == 'financial' || role == 'admin' || role == 'sales') && order.order_state != 'cancel' && order.order_state != 'estimate'" class="mt-5 mb-5 p-5 bg-white shadow">
      <h2 class="mb-8 text-xl font-bold">Pagos</h2>
      <payment-box v-if="order.order_state != 'finish'" :quick="true" :payment_methods="payment_methods" :balance="order.total - order.balance" :change="order.change" :order="order" />
      <!-- <div class="pt-2">
        <div class="pb-8 pr-6 w-full">
          <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo divisas</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="pago-movil">Pago móvil Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="zelle">Zelle</option>
            <option value="credito">Credito</option>
          </select>
        </div>
        <text-input v-model="form.amount" type="number" step="0.10" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
        <text-input v-if="form.method === 'transferencia' || form.method === 'transferencia-usd' || form.method === 'zelle' || form.method === 'pago-movil'" v-model="form.reference" :error="form.errors.reference" class="pb-8 pr-6 w-full" label="Referencia" />

        <button @click="addPay" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Agregar pagos</button>
      </div> -->
      <div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">Metodo</th>
              <th class="pb-4 pt-6 px-6">Monto</th>
              <th class="pb-4 pt-6 px-6">Fecha</th>
              <th class="pb-4 pt-6 px-6">Referencia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pay in order.pays" :key="pay.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="p-2 text-center border-t capitalize">
                {{ pay.method }}
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.amount }} <span v-if="pay.amount_bs > 0">(BsD. {{pay.amount_bs}})</span>
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.created_at }}
              </td>
              <td class="p-2 text-center border-t">
                {{ pay.reference }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="p-5 bg-white rounded-md shadow overflow-x-auto">
      <h2 class="mb-8 text-xl font-bold">Productos</h2>
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr>
            <th class="pb-4 pt-6 px-6"></th>
            <th class="pb-4 pt-6 px-6">Producto</th>
            <th class="pb-4 pt-6 px-6">Descripcion</th>
            <th class="pb-4 pt-6 px-6">Deposito</th>
            <th class="pb-4 pt-6 px-6">Estado</th>
            <th>Cantidad</th>
            <th class="pb-4 pt-6 px-6">Precio</th>
            <th class="pb-4 pt-6 px-6">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, key) in order.products" :key="product.id" :class="`${product.dispatch ? 'bg-blue-300' : ''}`">
            <td class="p-2 text-center border-t">
              <input v-model="products.products[key]['dispatch']" :value="product.id" type="checkbox" />
            </td>
            <td class="p-2 text-center border-t">
              {{ product.product.name }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.product.description }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.deposit.name }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.dispatch ? 'Despachado' : 'No Despachado' }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.quantity }}
            </td>
            <td class="p-2 text-center border-t">${{ product.price }}</td>
            <td class="p-2 text-center border-t">${{ Math.round(product.price * product.quantity) }}</td>
          </tr>
        </tbody>
        <tfoot class="px-6 py-2" style="background-color: #f3f3f3">
          <tr>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"></td>
            <td class="p-2 text-center border-t"><strong>Total</strong></td>
            <td class="p-2 text-center border-t">${{ order.total }}</td>
          </tr>
        </tfoot>
      </table>
      <button v-if="(order.order_state == 'delivery')" @click="addDispatch" class="text-md my-2 p-2 text-white bg-teal-800 rounded">Despachar productos</button>
    </div>

    <div v-if="order.order_state != 'cancel' && order.order_state != 'estimate'" class="mt-5 p-5 bg-white shadow">
      <h2 class="mb-8 text-xl font-bold">Despachos</h2>
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr>
            <th class="pb-4 pt-6 px-6">Concepto</th>
            <th class="pb-4 pt-6 px-6">Productos</th>
            <th class="pb-4 pt-6 px-6">Fecha</th>
            <th class="pb-4 pt-6 px-6">Responsable</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dispatch in dispatches" :key="dispatch.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="p-2 text-center border-t">Se realizo el despacho al cliente</td>
            <td class="p-2 text-center border-t">
              <span v-for="prod in dispatch.products" :key="prod.id"> {{ prod.product.name }}, </span>
            </td>
            <td class="p-2 text-center border-t">
              {{ dispatch.date }}
            </td>
            <td class="p-2 text-center border-t">
              <!--{{ dispatch.responsible.first_name }} {{ dispatch.responsible.last_name }}-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="order.pays.length == 0" class="mt-5 p-5 text-right bg-white shadow">
      <button v-if="order.order_state != 'cancel'" @click="cancelOrder()" class="text-md p-2 text-white bg-red-500 rounded">Anular Orden</button>
    </div>

    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import PaymentBox from '../../Shared/PaymentBox.vue'

export default {
  components: {
    Head,
    Link,
    TextInput,
    PaymentBox
  },
  layout: Layout,
  props: {
    order: Object,
    dispatches: Object,
    payment_methods: Object
  },
  data() {
    return {
      role: this.$parent.auth.user.role,
      form: this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
      }),
      products: this.$inertia.form({
        products: this.order.products,
        order_id: this.order.id,
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    cancelOrder() {
      if (confirm('Estas seguro que quieres anular esta orden?')) {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },
    addDispatch() {
      this.products.post('/orders/dispatch')
    },
    addPay() {
      if (this.form.method === 'transferencia' || this.form.method === 'transferencia-usd' || this.form.method === 'zelle') {
        if (!this.form.reference) {
          alert('Debe ingresar una referencia')
          return false
        }
      }
      if (this.order.total < parseInt(this.form.amount) + parseInt(this.order.balance)) {
        return alert('No se puede agregar este pago si el monto es mayor al balance')
      }
      this.form.order_id = this.order.id
      this.form.post('/orders/pay')
      console.log(this.form)

      this.cleanPay()
    },
    changeOrderState()
    {
      this.$inertia.get(`/orders/${this.order.id}/changeState`)
    },
    cleanPay() {
      this.form = this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
      })
    },
  },
}
</script>
